<template>
  <input
    class="form-control"
    ref="inputRef"
    type="text"
    :value="formattedValue"
    :placeholder="placeholder"
    :disabled="disable"
  />
</template>

<script>
import { watch } from "vue"; // Vue 2: import { watch } from '@vue/composition-api'
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number, // Vue 2: value
    options: Object,
    placeholder: String,
    disable: Boolean,
  },
  setup(props) {
    const { inputRef, formattedValue, setOptions, setValue } = useCurrencyInput(
      props.options
    );

    watch(
      () => props.modelValue,
      (value) => {
        // Vue 2: props.value
        setValue(value);
      }
    );

    return { inputRef, formattedValue };
  },
};
</script>
